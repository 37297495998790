import * as bootstrap from "bootstrap"; // eslint-disable-line @typescript-eslint/no-unused-vars
import { assert } from "common";

function toggleToggleButtonAriaExpanded(event: Event, state: boolean): void {
	if (event.target instanceof Element && event.target.matches(".submenu")) {
		const toggleButton = event.target.parentNode?.querySelector(":scope > .navgroup-toggle") ?? null;
		if (toggleButton !== null) {
			toggleButton.setAttribute("aria-expanded", state ? "true" : "false");
		}
	}
}

const nav = document.querySelector("nav.sidenav");
if (nav !== null) {
	nav.addEventListener("click", function(this: Element, event: Event) {
		for (let target = event.target; target !== null && target !== this; assert(target instanceof Element), target = target.parentNode) {
			if (target instanceof Element && target.matches(".navgroup-toggle")) {
				const submenu = target.parentNode?.querySelector(":scope > .submenu") ?? null;
				if (submenu !== null) {
					window.bootstrap.Collapse.getOrCreateInstance(submenu).toggle();
				}
			}
		}
	});

	// Use event listeners to toggle aria-exapanded, else double clicks will toggle aria-expanded but not the collapsed area
	nav.addEventListener("show.bs.collapse", (e) => toggleToggleButtonAriaExpanded(e, true));
	nav.addEventListener("hide.bs.collapse", (e) => toggleToggleButtonAriaExpanded(e, false));
}
